.apple-sign-in {
    appearance: none;
    -webkit-appearance: none;
    padding: 10px;
    border: none;
    background: #000;
    font-size: 14px;
    cursor: pointer;
    border-radius: 7px;
    font-weight: 500;
    
    width: 100%;
    color: #fff;
   
}
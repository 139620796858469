#count__down {
  float: right !important;
  margin-right: 70px;
  color: orangered;
  font-size: larger;
  font-weight: bold;
  bottom: 30px;
  position: relative;
}
.range-footer {
  width: 95vw !important;

  margin-bottom: 50px;
  margin-top: 0px;
  margin-left: 38px;
  margin-right: 38px;
}
.stop-scroll-div {
  position: relative;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: orangered;
}
button#startAudioRecording {
  display: none;
}

button#stopAudioRecording {
  display: none;
}
canvas.audio-react-recorder__canvas {
  display: none;
}
#seek__range {
  position: relative;
  bottom: 30px;
}

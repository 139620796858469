._loading_overlay_overlay {
  background-color: #000000c4 !important;
  z-index: 999999;
}
/* .css-79elbk {
  position: relative;
  background: #000;
} */
.thumbnail_video {
  z-index: 999;
}
.tab_container {
  width: 100%;
}
.toggle-footer {
  margin-top: -20px;
}

.tab_active svg path,.tab_active {
  fill: #ff5f00 !important;
  color: #ff5f00;
}



.blobs-container {
  float: right;
  position: fixed;
  right: 70px;
  top: 50px;
  z-index: 1;
}
.record-text {
  margin-right: 0.5rem !important;
  position: absolute;
  left: 23px;
  top: -2px;
}
.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.blob.orange {
  background: rgba(255, 121, 63, 1);
  box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
  animation: pulse-orange 2s infinite;
}

.start-video img {
  vertical-align: middle;
  border-style: none;
  width: 50px;
  margin-bottom: -22px;
  position: relative;
  top: -30px;
}

.record-icon img {
  width: 40px;
  margin: -30px 0 0 0;
  z-index: 99999;
}
img.video-record-icon {
  width: 48px !important;
}

.toggle-footer-bottom {
  top: 92px;
  position: absolute;
}

.script-scroll-heading {
  font-size: 1.25rem;
  position: fixed;
  background: #000;
  width: 100vw;
  top: 0px;
  padding: 10px;
  text-align: center;
  color: #fff;
  left: 0px;
  z-index: 1;
}

#full__video {
  background-color: #000;
}
#contentDiv {
  padding-top: 70px !important;
  padding-bottom: 400px !important;
  scroll-snap-type: y mandatory !important;
}
#scroll__content {
  padding-top: 0px !important;
  scroll-snap-type: y mandatory !important;
  padding-bottom: 10px !important;
}
/* #contentDiv p {
  color: #fff;
} */
.stop-scroll-div {
  cursor: pointer;
  color: orangered;
  margin: 0 auto;
  width: 0%;
  right: 25px;
}
.stop-scroll-div img {
  width: 40px;
}

.premeeting-screen {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  font-size: 1.3em;
  z-index: 251;
}
.premeeting-screen {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#preview {
  height: 100%;
  position: absolute;
  width: 100%;
}

#preview video {
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
}
::-webkit-scrollbar {
  width: 5px;
  scrollbar-width: 5px;
  background-color: rgb(0, 0, 0);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(255, 116, 92);
}

#movable {
  position: fixed;
  /* height: 200px;
  width: 200px; */

  z-index: 99990;
}
.toggle-footer-bottom {
  top: 92px;
  position: absolute;
  z-index: 99999;
}
#voiceRecoMedia1 {
  position: fixed;
  z-index: 1;
  right: 0;
  top: 1em;

  -webkit-transform: translate3d(0, 0, 9999px);

  width: 320px;
  box-sizing: border-box;
  color: #fff;
}
#voiceRecoMedia1 .MuiAlert-standardError {
  color: #fff;
  background-color: #e74c3c;
}
#voiceRecoMedia1 .MuiAlert-standardError .MuiAlert-icon {
  display: none;
}

#voiceRecoMedia1 .mediaDevicesErrorIcon {
  float: right;
  right: 15px;
  position: absolute;
  top: 4px;
  font-size: 0.9em;
  cursor: pointer;
}
#voiceRecoMedia1 .MuiAlert-message {
  padding: 0px 0px;
}

/* ============================= */

.main-prompt-page-outer {
  position: relative;
}
.main-prompt-page-hidden {
  position: absolute;
  top: 10px;
  z-index: -9999;
}

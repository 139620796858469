span#button-text {
  font-weight: 500;
}
.PaymentRequestButton-icon {
  display: none !important;
}

.checkout-page.new-redirect-checkout-page {
  height: 100vh;
  width: 50%;
  margin: auto;
  padding: 0px;
}

button.btn.btn-primary.btn-lg.btn-block.custom-btn.border-0.btn-login.create-account-checkout {
  /* min-width: 30%; */
  width: auto;
  margin: auto;
  height: 45px;
  font-size: 1.1rem;
}
p.text-signup.text-left.checkout-titles {
  font-weight: 600;
  font-size: 20px;
}

.toggle-text span {
  font-size: 16px;
  margin: 7px;
}

.tab-color {
  color: blue;
}
.shield_icon {
  padding-right: 2px;
}
.shield_icon path {
  fill: green;
}

span.text-shield-stripe {
  font-weight: 600;
}

button.btn.btn-primary.btn-lg.btn-block.custom-btn.border-0.btn-login.verify-account {
  background: green;
  font-size: 1.1rem;
  width: auto;
}
.checkout-page button.btn.btn-primary.btn-lg.btn-block.custom-btn {
  font-size: 1rem;
}

.default-stripe-form input {
  height: 45px;
}

.default-stripe-form .default-pay-btn {
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  /* padding: 12px 15px; */
  color: #fff;
  /* margin-top: 5px; */
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin: 0;
  border-radius: 5px;
  text-decoration: none;
  /* width: 50%; */
  text-align: center;
  font-size: 1.1rem;
  background-image: linear-gradient(to right, #fe2e47, #ff8c57);
  border: none;
  margin: auto;
}

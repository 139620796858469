/* Convert kit form css start */

.ck-fm {
  width: 90%;
  max-width: 400px;
  margin: auto;
  padding: 30px 20px;
  border-radius: var(--curve);
  background: linear-gradient(45deg, crimson, papayawhip);
}
.link_color,
.link_color:hover {
  color: #007bff !important;
  font-weight: 500;
}
.arrow_icon {
  font-size: small !important;
}

.link_color.terms,
.link_color.terms:hover {
  text-decoration: underline !important;
}

.ck-fm input,
.ck-fm button {
  width: 100%;
  padding: 10px;
  border: 0;
  border-radius: inherit;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.ck-fm input {
  display: block;
  height: 35px;
  margin: 0 0 15px;
}

.ck-fm button {
  background: rgba(255, 255, 255, 0.5);
  color: #775;
  font-weight: bold;
  transition: transform 0.3s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.2s linear;
  cursor: pointer;
}

.ck-fm button:hover {
  transform: scale(1.02);
  opacity: 0.8;
}

/* Convert kit form css end */

.drive-modal-cancle {
  position: absolute;
  right: 15px;
  cursor: pointer;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.drive-modal-ok {
  background-color: #ff8d6e;
  color: #fff;
  width: 150px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.drive-modal-ok:hover {
  background-color: #ff8d6e;
  color: #fff;
}
.MuiDialogActions-root.MuiDialogActions-spacing.drive-dialog {
  justify-content: center;
}
.MuiDialog-paperWidthSm {
  max-width: 700px !important;
  padding-bottom: 15px;
}
button#demo-customized-button {
  bottom: 2px !important;
}

.css-orcc5d-MuiButtonBase-root-MuiButton-root {
  padding: 4px !important;
  border-radius: 0px !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  position: relative;
  float: none !important;
  left: -7px;
}
.main_board_content {
  background-color: #fff;
}
button:focus {
  outline: none !important;
}
.google_native {
  width: 40%;
  top: 25%;
  position: absolute;
  margin: 50 auto;
  left: 30%;
  right: 30%;
}
.google_native .google-btn {
  padding: 2px !important;
}
.profile-img-native {
  position: relative;
  margin: 0px 0px 30px 40%;
}
.google_native {
  background-color: #f3f1f1;
  /* padding: 6px 12px 2% 14px; */
  padding: 40px;
  border-radius: 10px;
}

.google_native button.fb-btn1 {
  border: 0;
  background-color: #00a3f1;
  color: #fff;
  font-size: 14px;

  border-radius: 5px;
  width: 100%;
  margin-bottom: 1rem;
}

button.fb-btn1.metro {
  height: 50px;
}

#subscription-modal::-webkit-scrollbar {
  width: 5px !important;
  scrollbar-width: 5px !important;
  background-color: #fff;
}

#subscription-modal::-webkit-scrollbar-thumb {
  /* border-radius: 10px; */
  background-color: #a9a9a9;
}

/* .WithStyles\(ForwardRef\(Accordion\)\)-root-2.WithStyles\(ForwardRef\(Accordion\)\)-expanded-3 {
  margin: auto;
  bottom: 20px;
} */

/* .WithStyles\(ForwardRef\(Accordion\)\)-root-2:not(:last-child) {
  border-bottom: 0;
  bottom: 20px;
} */

div#contentDiv {
  background-color: #000 !important;
  /* height: 100vh; */
}

.close-btn {
  position: absolute;
  right: 35px;
  top: 15px;
}
.logo-top {
  color: #333;
}
.check-with-text {
  color: #333;
  text-align: left;
}
span.text-point {
  padding-left: 13px;
}
.pricing {
  text-align: center;
}
/* .row.custom-row {
  width: 70%;
  margin: 1rem 0 0 12rem;
} */
.price-sec-wrap {
  width: 100%;
  float: left;
  padding: 10px 0;
  font-family: "Lato", sans-serif;
}
.main-heading {
  text-align: center;
  font-weight: 600;
  padding-bottom: 15px;
  position: relative;
  text-transform: capitalize;
  font-size: 24px;
  margin-bottom: 5px;
}
.price-box.active {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background: linear-gradient(0.4turn, #fe2e48, #f58753);
  border-radius: 4px;
}

.profile-sidebar.d-flex.orange-line {
  bottom: 40px;
}

.inner-upgrade-content {
  margin-left: 76px;
  cursor: pointer;
}

.inner-upgrade-content .free {
  padding: 6px 0 0 5px;
  font-size: 0.7rem;
  color: #fff;
  font-weight: 400;
}
/* .MuiDialog-paperWidthSm {
  max-width: 850px !important;
} */

.price-label {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.34;
  margin-bottom: 0;
  padding: 6px 15px;
  display: inline-block;
  border-radius: 3px;
}

.price {
  font-size: 25px;
  line-height: 44px;
  margin: 0px 0 6px;
  font-weight: 900;
  color: #fff;
}
.price-info {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.67;
  color: inherit;
  width: 100%;
  margin: 0;
  color: #fff;
  margin-top: 10px;
}
.plan-btn {
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  padding: 11px 30px;
  color: #000;
  margin-top: 5px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin: 0;
  border-radius: 5px;
  text-decoration: none;
  width: 100%;
  text-align: center;
  font-size: 14px;
  background-color: #fff;
  margin-top: 3rem;
  border: none;
}
.price-box-inactive {
  background-color: #ececec;
  padding: 20px;
  border-radius: 4px;
  color: #000;
  margin-bottom: 20px;
}
.price-box-inactive div {
  color: #979797;
}
span.plan-btn.subscription,
a.plan-btn.subscription {
  background: linear-gradient(0.4turn, #fe2e48, #f58753);
  width: 50%;
  margin: 25px auto;
  color: #fff;
  font-size: 16px;
}
span.plan-btn.subscribed,
a.plan-btn.subscribed {
  background: #e5e5e5;
  width: 50%;
  margin: 25px auto;
  color: rgb(0, 0, 0);
  font-size: 16px;
}
.gray-box {
  background-color: #ececec;
  padding: 2rem;
  border-radius: 5px;
  text-align: left;
}

/* new-css-13-jan-23 */

.model-header-icons {
  position: absolute;
  right: 0;
  top: 0px;
  cursor: pointer;
}
h2.head-mod {
  background-color: #333;
  color: #fff;
  padding: 7px;
}
.model-header-icons img {
  margin: 0 5px 0 5px;
}
.footer_custom {
  width: 100% !important;
  background-color: #333;
}
.footer_custom.seek-enable {
  width: 100% !important;
  background-color: rgba(51, 51, 51, 0);
}
button.play_pause svg path {
  fill: #ff5f00;
}
button.play_pause img {
  /* font-size: 40px; */
  margin: -30px 0 0 0;
  z-index: 9999;
  position: fixed;
  width: 50px;
}

.footer-timer {
  position: absolute;
  float: right;
  right: 15px;
  bottom: -6px;
  color: #ff5f00;
}
.stop-seek-icon {
  position: fixed;
  width: 30px;
  margin: -35px 0px 0px 0px;
  cursor: pointer;
}
.footer_custom.seek-enable .seek-bar {
  padding: 0 25px 20px 25px;
}
button.Mui-selected.tabs_icons {
  color: #ff5f00 !important;
}
button.tabs_icons {
  color: #fff !important;
}
.footer_custom span.MuiTabs-indicator {
  background-color: transparent;
}
.gray-bg-back-side {
  background-color: #404146;
  height: 45px;
  padding: 5px 15px 5px 5px;
}
.gray-bg-back-side p {
  color: #fff;
  font-size: 14px;
}
.Model-edit-heading-line {
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
}
.Model-edit-heading-line p {
  font-size: 14px;
  color: #fff;
}
.gray-bg-back-side.mini-gray {
  background-color: #404146;
  height: 46px;
  padding: 5px;
}
p.trans-text {
  font-size: 14px;
}
.model-center-content.MuiBox-root.css-0 {
  padding: 0 15px 15px 15px;
}

/* new child window  */
.video-player button,
.video-player svg,
.ecCRdp,
.kBCwrU,
.cWtqoj,
.cWtqoj,
.dRmQAW,
.hEjUYU {
  display: none;
}

.mini-screen .emTeya {
  min-height: 160px;
  width: 160px;
}

.video-player {
  position: fixed;
  top: 0;
  z-index: -1;
}

.video-player.mini-screen {
  z-index: 9;
}

.video-player div:first-child {
  position: fixed;
  background-color: transparent;
}
.video-player div:first-child div:last-child {
  display: none;
}

.video-player.mini-screen video {
  border-radius: 15px;
}

.video-player.mini-screen div:first-child,
.video-player.mini-screen div:first-child div:first-child {
  width: 160px;
  height: 160px;

  transition: all 500ms ease-in-out;
}

.video-player.mini-screen.topLeft div:first-child div:first-child {
  top: 50px;
  left: 20px;

  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.topRight div:first-child div:first-child {
  top: 50px;
  right: 20px;

  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.bottomLeft div:first-child div:first-child {
  bottom: 50px;
  left: 20px;

  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.bottomLeft.footerOn div:first-child div:first-child {
  bottom: 270px;
  left: 20px;
  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.bottomLeft.footerOn.footerOff
  div:first-child
  div:first-child {
  bottom: 50px;
  left: 20px;
  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.bottomRight.footerOn.footerOff
  div:first-child
  div:first-child,
.video-player.mini-screen.bottomRight.footerOn.footerOff.footerSizeChange
  div:first-child
  div:first-child {
  bottom: 50px;
  right: 20px;
  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.bottomLeft.footerOn.footerSizeChange
  div:first-child
  div:first-child {
  bottom: 230px;
  left: 20px;
  transition: all 500ms ease-in-out;
}

.video-player.mini-screen.bottomRight div:first-child div:first-child {
  bottom: 50px;
  right: 20px;
  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.bottomRight.footerOn div:first-child div:first-child {
  bottom: 270px;
  right: 20px;

  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.bottomRight.footerOn.footerSizeChange
  div:first-child
  div:first-child {
  bottom: 230px;
  right: 20px;
  transition: all 500ms ease-in-out;
}

/* NEW CSS */

.sidebar_add_header {
  display: flex;
  justify-content: left;
  column-gap: 10px;
}

.sidebar_add_header.add_folder {
  padding: 0 0 10px 20px;
}
/* START MEDIA QUERIES */

@media (max-width: 1280px) {
  /* .row.custom-row {
    width: 100%;
    margin: 0 0 0 6rem !important;
  } */
}
@media (max-width: 991px) {
  .price-box {
    margin-bottom: 20px;
  }
}
@media (max-width: 575px) {
  .select-script {
    margin-left: 10px;
  }
  form.search-top {
    margin-bottom: 10px;
  }
  a.plan-btn.subscription {
    width: 100%;
  }
  /* .row.custom-row {
    width: 100%;
    margin: 2rem 0 0 0rem;
  } */
  .main-heading {
    font-size: 21px;
  }
  .price-box {
    margin-bottom: 20px;
  }
}
._loading_overlay_wrapper.css-79elbk {
  overflow-y: visible;
}

.checkout-page {
  padding: 2rem 8rem;
  height: 100vh;
}
.delete-account {
  text-align: center;
  padding-top: 3rem;
}
/* 
.range-margin.speed {
  width: 91vw !important;
} */

span.margin_custom {
  width: 6%;
  padding-top: 4px;
}

.running_icon {
  width: 15px;
}

.running_icon_fast {
  width: 28px;
  margin-left: 5px;
}

.toggle-footer-icon svg path {
  fill: #fff;
}
.toggle-footer-icon svg:hover > path {
  fill: #ff5f00;
}
.toggle-footer-icon {
  position: relative;
  right: 15px;
  top: 7px;
}

.cursor {
  cursor: pointer;
}

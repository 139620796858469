.sb-icon-search-1 {
  z-index: 90;
  font-size: 22px;
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}
.sb-icon-search-1,
.sb-search-submit {
  width: 60px;
  height: 60px;
  display: block;
  position: absolute;

  top: 0;
  padding: 0;
  margin: 0;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
}
input.form-control.input-lg.custom-search-tab {
  padding: 0 0 0 50px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul.dictionary {
  max-height: 100px;
  overflow-x: auto;
  overflow-y: scroll;
}
ul.dictionary .synonym {
  color: #000;
}

p.search-cancel {
  padding: 2.2rem 0 0 1rem;
}

ul.dictionary::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* background-color: #f5f5f5; */
  border-radius: 10px;
}

ul.dictionary::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

ul.dictionary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.86, rgb(255, 116, 92))
  );
}

.css-gjl7sn {
  position: absolute;
  top: 35px;
  z-index: 999999;
  right: 15%;
}

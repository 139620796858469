.story-board-description {
  height: 30vh !important;
}
.story__boards {
  overflow-x: hidden;
  /* overflow-y: scroll; */
}
.main-heading-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.select-script,
.all-script {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.7em;
  color: #1a5b8f;
  cursor: pointer;
  margin-left: 50px;
}
.select-script,
.all-script,
.heading-title {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
/* .script {
  height: 20px;
  width: 20px;
  visibility: visible;
  position: absolute;
  float: left;
  margin-left: -30px;
  top: 20px;
} */

.folder {
  height: 20px;
  width: 20px;
  visibility: visible;
  /* position: absolute; */
  float: right;
  margin-right: 20px;
  background-color: black !important;
}

.add_folder {
  cursor: pointer;
}

.cancle {
  cursor: pointer;
  color: #f59170;
  position: relative;
  left: 45%;
}
.option {
  float: right;

  position: absolute;
  margin: 0px 160px;
}
.dropdown-toggle::after {
  display: none !important;
}
.img-fluid {
  cursor: pointer;
}
.dropdown-item {
  cursor: pointer;
}
.dropdown-item:hover {
  background-color: rgb(231 236 239);
}
.story-board-description input {
  border: none !important;
}
.story-board-description input:hover {
  border: none !important;
  width: 100%;
}

.story-board-description {
  height: 30vh !important;
  width: 100%;
}

.select-script {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 500;
  line-height: 1.7em;
  color: #1a5b8f;
  cursor: pointer;
  margin-left: 50px;
}

.folder {
  height: 20px;
  width: 20px;
  visibility: visible;
  /* position: absolute; */
  float: right;
  margin-right: 20px;
  background-color: black !important;
}

.add_folder {
  cursor: pointer;
}

#pageSubmenu,
#pageSubmenu2 {
  /* overflow-y: scroll; */

  max-height: 150px;
}
.collapse.show {
  display: block;
  overflow-y: auto;
  min-height: 100px !important;
  color: inherit;
  transition: all 0.5s;
}
.show-all-scripts {
  cursor: pointer;
}
.show-all-scripts:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.show-all-script {
  overflow-y: auto;
  height: 72.5vh;
  top: -28px;
  left: -7px;
  overflow-x: hidden;
}

.show-all-script::-webkit-scrollbar {
  /* width: 5px;
  scrollbar-width: 5px; */
  background-color: #f5f5f5;
}
.show-all-script::-webkit-scrollbar-thumb {
  background-color: #a9a9a9 !important;
}

.script__content::-webkit-scrollbar {
  width: 0px;
  scrollbar-width: 0px;
  background-color: #f5f5f5 !important;
}

*/ .fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "\f00d";

  position: absolute;
  margin-left: -40px;
  margin-top: -14px;
}
.list-group-item {
  cursor: pointer;
}
.folder-list active {
  margin-top: 20px;
}

.folder-list.active {
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-image: url("./open-folder.svg");
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: contain;
}
.get-all-scripts.active {
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-image: url("./open-folder.svg");
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: contain;
}

.list-group-item:hover {
  cursor: pointer;
  background-color: #fff !important;
}
.nav.nav-tabs.nav-fill.custom-script h5 {
  font-size: 17px;
}
.list-group-item.active {
  background-color: #fff !important;
  color: #000;
  cursor: pointer;
}

#selectScript {
  margin-left: 85px;
}

.alert-msg {
  border-radius: 100px;
}

.scriptToolbar .DraftEditor-root {
  display: none;
}

.scriptEdit {
  padding-top: 0px !important;
  padding-bottom: 2% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
  height: auto;
  font-family: "Poppins" sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1em !important;
}

.css-df17o1 {
  background-color: rgb(0, 0, 0.1) !important;
  opacity: 0.9 !important;
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}

#content {
  overflow: hidden;
}
.script__content p {
  color: #000;
}

#scriptContentSize {
  padding-top: 0.6rem;
}

.script__content h5 {
  width: 85%;
  position: fixed;
  background-color: #fafafa;
  padding: 8px 0 10px 0;
}

.script__content p {
  color: #000 !important;
}

.main__scripts {
  min-height: 100px;
  padding-right: 10px;
}
article::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* background-color: #f5f5f5; */
  border-radius: 10px;
}

article::-webkit-scrollbar {
  width: 5px;
  background-color: #fff !important;
}
textarea.story-board-description::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5 !important;
}

article::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #a9a9a9 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff00;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
  border: 1px solid #ced4da;
}

.apple-sign-in {
    appearance: none;
    -webkit-appearance: none;
    padding: 10px;
    border: none;
    background: #000;
    font-size: 14px;
    cursor: pointer;
    border-radius: 7px;
    font-weight: 500;
    
    width: 100%;
    color: #fff;
   
}
.story-board-description {
  height: 30vh !important;
}
.story__boards {
  overflow-x: hidden;
  /* overflow-y: scroll; */
}
.main-heading-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.select-script,
.all-script {
  font-family: "Poppins", sans-serif;
  font-size: 1em;
  font-weight: 500;
  line-height: 1.7em;
  color: #1a5b8f;
  cursor: pointer;
  margin-left: 50px;
}
.select-script,
.all-script,
.heading-title {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none;
}
/* .script {
  height: 20px;
  width: 20px;
  visibility: visible;
  position: absolute;
  float: left;
  margin-left: -30px;
  top: 20px;
} */

.folder {
  height: 20px;
  width: 20px;
  visibility: visible;
  /* position: absolute; */
  float: right;
  margin-right: 20px;
  background-color: black !important;
}

.add_folder {
  cursor: pointer;
}

.cancle {
  cursor: pointer;
  color: #f59170;
  position: relative;
  left: 45%;
}
.option {
  float: right;

  position: absolute;
  margin: 0px 160px;
}
.dropdown-toggle::after {
  display: none !important;
}
.img-fluid {
  cursor: pointer;
}
.dropdown-item {
  cursor: pointer;
}
.dropdown-item:hover {
  background-color: rgb(231 236 239);
}
.story-board-description input {
  border: none !important;
}
.story-board-description input:hover {
  border: none !important;
  width: 100%;
}

.story-board-description {
  height: 30vh !important;
  width: 100%;
}

.select-script {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 500;
  line-height: 1.7em;
  color: #1a5b8f;
  cursor: pointer;
  margin-left: 50px;
}

.folder {
  height: 20px;
  width: 20px;
  visibility: visible;
  /* position: absolute; */
  float: right;
  margin-right: 20px;
  background-color: black !important;
}

.add_folder {
  cursor: pointer;
}

#pageSubmenu,
#pageSubmenu2 {
  /* overflow-y: scroll; */

  max-height: 150px;
}
.collapse.show {
  display: block;
  overflow-y: auto;
  min-height: 100px !important;
  color: inherit;
  transition: all 0.5s;
}
.show-all-scripts {
  cursor: pointer;
}
.show-all-scripts:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.show-all-script {
  overflow-y: auto;
  height: 72.5vh;
  top: -28px;
  left: -7px;
  overflow-x: hidden;
}

.show-all-script::-webkit-scrollbar {
  /* width: 5px;
  scrollbar-width: 5px; */
  background-color: #f5f5f5;
}
.show-all-script::-webkit-scrollbar-thumb {
  background-color: #a9a9a9 !important;
}

.script__content::-webkit-scrollbar {
  width: 0px;
  scrollbar-width: 0px;
  background-color: #f5f5f5 !important;
}

*/ .fa-remove:before,
.fa-close:before,
.fa-times:before {
  content: "\F00D";

  position: absolute;
  margin-left: -40px;
  margin-top: -14px;
}
.list-group-item {
  cursor: pointer;
}
.folder-list active {
  margin-top: 20px;
}

.folder-list.active {
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-image: url(/static/media/open-folder.83f6a780.svg);
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: contain;
}
.get-all-scripts.active {
  /* background-color: rgba(0, 0, 0, 0.2); */
  background-image: url(/static/media/open-folder.83f6a780.svg);
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  background-size: contain;
}

.list-group-item:hover {
  cursor: pointer;
  background-color: #fff !important;
}
.nav.nav-tabs.nav-fill.custom-script h5 {
  font-size: 17px;
}
.list-group-item.active {
  background-color: #fff !important;
  color: #000;
  cursor: pointer;
}

#selectScript {
  margin-left: 85px;
}

.alert-msg {
  border-radius: 100px;
}

.scriptToolbar .DraftEditor-root {
  display: none;
}

.scriptEdit {
  padding-top: 0px !important;
  padding-bottom: 2% !important;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
  height: auto;
  font-family: "Poppins" sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1em !important;
}

.css-df17o1 {
  background-color: rgb(0, 0, 0.1) !important;
  opacity: 0.9 !important;
}

[contenteditable]:focus {
  outline: 0px solid transparent;
}

#content {
  overflow: hidden;
}
.script__content p {
  color: #000;
}

#scriptContentSize {
  padding-top: 0.6rem;
}

.script__content h5 {
  width: 85%;
  position: fixed;
  background-color: #fafafa;
  padding: 8px 0 10px 0;
}

.script__content p {
  color: #000 !important;
}

.main__scripts {
  min-height: 100px;
  padding-right: 10px;
}
article::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* background-color: #f5f5f5; */
  border-radius: 10px;
}

article::-webkit-scrollbar {
  width: 5px;
  background-color: #fff !important;
}
textarea.story-board-description::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5 !important;
}

article::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #a9a9a9 !important;
}

.gray-story-board {
  background-color: #e5e5e5;
  padding: 15px 0 5px 20px;
  border-radius: 10px 10px 0 0;
  height: 48px;
}

.optionBtn {
  float: right;
  margin: 0 16px 0 0;
}
.story-board-description {
  color: #000;
}

.editBoard {
  border: none !important;
  outline: none;
}
.textarea {
  outline: none;
}
.save {
  border: none;
  margin-left: 10px;
  font-weight: 500;
  color: #f59170;
}
[contenteditable]:focus {
  outline: 0px solid transparent;
}
h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2em;
}

.save-board-btn {
  position: absolute;
  margin: -50px 15px;
}
.story-borad p {
  font-size: 15px;
  padding: 10px 20px 35px 20px;
  overflow-y: scroll;
}
.story-borad button {
  float: right;
  display: flex;
}

.story-borad p::-webkit-scrollbar {
  width: 10px;
  scrollbar-width: 10px;
  background-color: #fff !important;
}

.story-borad textarea::-webkit-scrollbar-thumb {
  background-color: #a9a9a9 !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  position: relative;
  float: left;
  left: -7px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text:hover {
  background: none;
}

#sidebar .react-contextmenu-item:hover {
  background-color: #e7ecef;
}
textarea.story-board-description {
  font-size: 15px;
  padding: 10px 20px 35px 20px;
  overflow-y: hidden;
  border: none;
  transition: width 2s;
}

textarea.story-board-description:hover {
  overflow-y: auto;
}

/* ul.main-folder-div {
  height: 165px;
} */
#recent_text {
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
#folder_text {
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.collapse.show {
  display: block;
  overflow-y: auto;
  min-height: 112px !important;
  color: inherit;
  transition: all 0.5s;
}

.main_modal {
  width: 100%;
}
/* .MuiDialog-paperWidthSm {
  max-width: 600px;
  width: 100% !important;
} */
.MuiAccordionDetails-root {
  display: grid !important;
  padding: 0px 16px 16px;
}
.recent-script-title {
  padding-left: 20px;
}
.add-new-script.mb-3 {
  padding-left: 20px;
}
ul.recent-content-body {
  padding-left: 10px;
}
.close__icon {
  color: #b6afaf;
  font-weight: 400;
  font-size: initial;
  right: 0px;
  top: -5px;
  position: relative;
  cursor: pointer;
}
/* .folder-list {
  cursor: pointer;
  word-break: break-all;
  padding: 0px 0px 0 0;
  position: relative;
  margin-left: 45px;
} */

.folder-list {
  cursor: pointer;
  word-break: break-all;
  padding: 0px 0px 0 0;
  position: relative;
  margin-left: 8px;
  background-image: url(/static/media/folder-icon.a9533622.svg);
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
}
.get-all-scripts {
  background-image: url(/static/media/folder-icon.a9533622.svg);
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
}
.get-all-scripts span {
  position: absolute;
  left: 17%;
}

.all-script {
  cursor: pointer;
}
.show-all-scripts:hover {
  background: none;
}
.show-all-scripts {
  font-size: 12px;
  left: 20px;
  position: relative;
  top: 0px;
  z-index: 9999;
  /* background-color: #153962; */
  width: 85%;
  /* padding: 0 0 10px 0px; */
}
.select-folders {
  height: 20px;
  width: 20px;
  margin-top: -20px;
  visibility: visible;
  left: 30px;
}
.recent__script {
  color: #fff !important;
  background: #003a66 !important;
}
.recent-script-header {
  color: #fff;
  left: 30px;
  border: none !important;
}
.recent-body ul {
  outline: none;
  list-style: none;
  font-size: 0.9em;
  /* overflow-x: hidden !important; */
}

.recent-body ul li div {
  font-size: 12px !important;
}
.MuiAccordionDetails-root {
  display: flex;
  padding: 0px 16px 16px;
}
.add-folder {
  position: relative;
  left: 25px;
}
.folder-body {
  position: relative;
  /* left: 50px; */
}

.recent-content-body li {
  cursor: pointer;

  width: 100%;
}
.recent-content-body li:hover {
  cursor: pointer;
}
.MuiDialog-paperWidthSm {
  max-width: 600px !important;
  width: 100%;
}

.MuiAccordionDetails-root {
  display: flex;
  padding: 0px 15px 0px;
}

.folder-container {
  position: relative;
}
.add-folder-icon img {
  width: 10%;
}

.folder-container .makeStyles-root-2 > * + * {
  margin-top: 0px !important;
}

.folder-container {
  flex: 1 1;
  overflow-y: auto;
  top: 0px;
  overflow-x: hidden;
}

.add-folder-icons {
  margin-left: 4px;
  position: absolute;

  z-index: 9;
  margin-top: 9px;
  left: 65px;
}
.add-folder-main {
  position: relative;
}
.add-folder-icon {
  position: absolute;
  right: 30px;
  top: 10px;
}

.add-folder-icon img {
  width: 20px;
}
.MuiAccordionDetails-root {
  display: flex;
  padding: 0px !important;
  left: 15px;
}

div#panel1d-header {
  left: 20px;
}

.folder-ul-list {
  position: relative;
  /* top: -8px; */
  /* max-height: 260px !important; */
  /* height: 40vh; */
  /* overflow-x: hidden;
  overflow-y: auto; */
}

.folder-selection {
  position: absolute;
  right: -20px;
  display: flex;
}

.folder-container::-webkit-scrollbar {
  width: 5px;
  scrollbar-width: 5px;
  background-color: #003a66 !important;
  border-radius: 5px;
}
.folder-container::-webkit-scrollbar-thumb {
  width: 8px;
  scrollbar-width: 8px;
  background-color: #a9a9a9;
  border-radius: 5px;
}
.select-all-folders {
  position: relative;
  left: 50px;
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 700;
  line-height: 1.7em;
  color: #f59170;
  cursor: pointer;
  /* margin-left: 30px; */
}
.WithStyles\(ForwardRef\(Accordion\)\)-root-2.WithStyles\(ForwardRef\(Accordion\)\)-expanded-3 {
  margin: auto;
  overflow: none;
  height: 10% !important;
}

/* .MuiCollapse-wrapperInner {
  width: 70% !important;
} */
.MuiTypography-body2 {
  font-family: "Poppins", sans-serif !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text:hover {
  padding: 9px 50px 9px 50px;
  background-image: linear-gradient(to right, #fe2e47, #ff8c57);
  border: 0px;
}

.delet-alert {
  background-color: #fff;
  border: none;
  font-weight: 600;
  margin-right: 15px;
}

.import-dropdown-menu:hover {
  background-color: #fafafa !important;
}

/* .color_picker,
.circle-picker {
  width: 147px !important;
  padding: 5px 0px 3px 8px !important;
} */

.color_picker,
.circle-picker {
  width: 147px !important;
  padding: 5px 0px 3px 8px !important;
  position: absolute;
  z-index: 1;
}

.circle-picker {
  border: 1px solid;
  background: rgba(255, 255, 255, 0.85);
  -webkit-backdrop-filter: blur(15px);
  position: absolute;
  border-radius: 5px;
  box-shadow: 0px 3px 8px rgb(0 0 0 / 20%);
  padding: 15px;
  font-family: "Open Sans", sans-serif;
  width: 140px;
  height: 140px;
  overflow: hidden;
}
.color_picker .circle-picker span div {
  border-radius: 5px !important;
  width: 20px !important;
  height: 20px !important;
  margin-right: 7px !important;
  margin-bottom: 7px !important;
}

.sb-icon-search-1 {
  z-index: 90;
  font-size: 22px;
  font-family: "icomoon";
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
}
.sb-icon-search-1,
.sb-search-submit {
  width: 60px;
  height: 60px;
  display: block;
  position: absolute;

  top: 0;
  padding: 0;
  margin: 0;
  line-height: 60px;
  text-align: center;
  cursor: pointer;
}
input.form-control.input-lg.custom-search-tab {
  padding: 0 0 0 50px;
}
.fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul.dictionary {
  max-height: 100px;
  overflow-x: auto;
  overflow-y: scroll;
}
ul.dictionary .synonym {
  color: #000;
}

p.search-cancel {
  padding: 2.2rem 0 0 1rem;
}

ul.dictionary::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* background-color: #f5f5f5; */
  border-radius: 10px;
}

ul.dictionary::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

ul.dictionary::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0.86, rgb(255, 116, 92))
  );
}

.css-gjl7sn {
  position: absolute;
  top: 35px;
  z-index: 999999;
  right: 15%;
}

.coupon {
  position: relative;
  width: 400px;
  height: 160px;
  margin: 50px auto;
  background-image: radial-gradient(
      circle at 1px 8px,
      transparent 6px,
      #642100 6px,
      #77503d 0px
    ),
    radial-gradient(
      circle at 199px 8px,
      transparent 6px,
      #642100 6px,
      #77503d 0px
    );
  background-size: 200px 18px;
  background-position: 0 0, 200px 0;
  /* background-repeat-x: no-repeat; */
  font-size: 60px;
  color: #fff;
  font-weight: bold;
  line-height: 160px;
  padding-left: 60px;
  box-sizing: border-box;
  cursor: pointer;
}
.coupon::before {
  position: absolute;
  content: "";
  left: 240px;
  top: 0;
  bottom: 0;
  width: 0;
  border-left: 1px dashed #fff;
}
.coupon::after {
  position: absolute;
  content: "50%";
  font-size: 26px;
  width: 70px;
  top: 50%;
  right: 2%;
  transform: translate(-50%, -50%);
  line-height: 40px;
  letter-spacing: 5px;
}

.react-contextmenu {
  background-color: #000;
  z-index: 999999;
  color: #fff;
  /* width: 100%; */
  padding: 5px 0px;

  border: 1px solid #e2e2e2;
  width: 120px;
  height: -moz-fit-content;
  height: fit-content;

  background-color: #fff;
  font-size: 16px;
  border-radius: 8px;

  color: #000;
}

.deleteScript {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */ /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
}

.script__container__paragraph {
  width: auto;
  height: 42px;
  text-overflow: ellipsis;

  overflow: hidden;
}

.dashboard-toggle {
  position: absolute;
  top: 55px;
  right: 30px;
}
.print-text p {
  color: #000;
}



.error {
  display: table;
  text-align: center;
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.cell {
  display: table-cell;
  vertical-align: middle;
}
.eror-img {
  display: inline-block;

  width: 450px;
  padding: 50px 0;
}

.error-back-home:hover {
  text-decoration: none;
}
.center{
      text-align: center;
    /* border: 3px solid green; */
    padding-top: 2rem;
    font-size: 1.3rem;
}
._loading_overlay_overlay {
  background-color: #000000c4 !important;
  z-index: 999999;
}
/* .css-79elbk {
  position: relative;
  background: #000;
} */
.thumbnail_video {
  z-index: 999;
}
.tab_container {
  width: 100%;
}
.toggle-footer {
  margin-top: -20px;
}

.tab_active svg path,.tab_active {
  fill: #ff5f00 !important;
  color: #ff5f00;
}



.blobs-container {
  float: right;
  position: fixed;
  right: 70px;
  top: 50px;
  z-index: 1;
}
.record-text {
  margin-right: 0.5rem !important;
  position: absolute;
  left: 23px;
  top: -2px;
}
.blob {
  background: black;
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  margin: 10px;
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

.blob.red {
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: pulse-red 2s infinite;
}

@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}

.blob.orange {
  background: rgba(255, 121, 63, 1);
  box-shadow: 0 0 0 0 rgba(255, 121, 63, 1);
  animation: pulse-orange 2s infinite;
}

.start-video img {
  vertical-align: middle;
  border-style: none;
  width: 50px;
  margin-bottom: -22px;
  position: relative;
  top: -30px;
}

.record-icon img {
  width: 40px;
  margin: -30px 0 0 0;
  z-index: 99999;
}
img.video-record-icon {
  width: 48px !important;
}

.toggle-footer-bottom {
  top: 92px;
  position: absolute;
}

.script-scroll-heading {
  font-size: 1.25rem;
  position: fixed;
  background: #000;
  width: 100vw;
  top: 0px;
  padding: 10px;
  text-align: center;
  color: #fff;
  left: 0px;
  z-index: 1;
}

#full__video {
  background-color: #000;
}
#contentDiv {
  padding-top: 70px !important;
  padding-bottom: 400px !important;
  scroll-snap-type: y mandatory !important;
}
#scroll__content {
  padding-top: 0px !important;
  scroll-snap-type: y mandatory !important;
  padding-bottom: 10px !important;
}
/* #contentDiv p {
  color: #fff;
} */
.stop-scroll-div {
  cursor: pointer;
  color: orangered;
  margin: 0 auto;
  width: 0%;
  right: 25px;
}
.stop-scroll-div img {
  width: 40px;
}

.premeeting-screen {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  font-size: 1.3em;
  z-index: 251;
}
.premeeting-screen {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

#preview {
  height: 100%;
  position: absolute;
  width: 100%;
}

#preview video {
  height: 100%;
  object-fit: cover;
  position: absolute;
  width: 100%;
}
::-webkit-scrollbar {
  width: 5px;
  scrollbar-width: 5px;
  background-color: rgb(0, 0, 0);
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(255, 116, 92);
}

#movable {
  position: fixed;
  /* height: 200px;
  width: 200px; */

  z-index: 99990;
}
.toggle-footer-bottom {
  top: 92px;
  position: absolute;
  z-index: 99999;
}
#voiceRecoMedia1 {
  position: fixed;
  z-index: 1;
  right: 0;
  top: 1em;

  -webkit-transform: translate3d(0, 0, 9999px);

  width: 320px;
  box-sizing: border-box;
  color: #fff;
}
#voiceRecoMedia1 .MuiAlert-standardError {
  color: #fff;
  background-color: #e74c3c;
}
#voiceRecoMedia1 .MuiAlert-standardError .MuiAlert-icon {
  display: none;
}

#voiceRecoMedia1 .mediaDevicesErrorIcon {
  float: right;
  right: 15px;
  position: absolute;
  top: 4px;
  font-size: 0.9em;
  cursor: pointer;
}
#voiceRecoMedia1 .MuiAlert-message {
  padding: 0px 0px;
}

/* ============================= */

.main-prompt-page-outer {
  position: relative;
}
.main-prompt-page-hidden {
  position: absolute;
  top: 10px;
  z-index: -9999;
}

.record-icon img {
  width: 45px;
  margin: -30px 0 0 0;
}

#count__down {
  float: right !important;
  margin-right: 70px;
  color: orangered;
  font-size: larger;
  font-weight: bold;
  bottom: 30px;
  position: relative;
}
.range-footer {
  width: 95vw !important;

  margin-bottom: 50px;
  margin-top: 0px;
  margin-left: 38px;
  margin-right: 38px;
}
.stop-scroll-div {
  position: relative;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  color: orangered;
}
button#startAudioRecording {
  display: none;
}

button#stopAudioRecording {
  display: none;
}
canvas.audio-react-recorder__canvas {
  display: none;
}
#seek__range {
  position: relative;
  bottom: 30px;
}

/* Convert kit form css start */

.ck-fm {
  width: 90%;
  max-width: 400px;
  margin: auto;
  padding: 30px 20px;
  border-radius: var(--curve);
  background: linear-gradient(45deg, crimson, papayawhip);
}
.link_color,
.link_color:hover {
  color: #007bff !important;
  font-weight: 500;
}
.arrow_icon {
  font-size: small !important;
}

.link_color.terms,
.link_color.terms:hover {
  text-decoration: underline !important;
}

.ck-fm input,
.ck-fm button {
  width: 100%;
  padding: 10px;
  border: 0;
  border-radius: inherit;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.ck-fm input {
  display: block;
  height: 35px;
  margin: 0 0 15px;
}

.ck-fm button {
  background: rgba(255, 255, 255, 0.5);
  color: #775;
  font-weight: bold;
  transition: transform 0.3s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.2s linear;
  cursor: pointer;
}

.ck-fm button:hover {
  transform: scale(1.02);
  opacity: 0.8;
}

/* Convert kit form css end */

.drive-modal-cancle {
  position: absolute;
  right: 15px;
  cursor: pointer;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.drive-modal-ok {
  background-color: #ff8d6e;
  color: #fff;
  width: 150px;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text.drive-modal-ok:hover {
  background-color: #ff8d6e;
  color: #fff;
}
.MuiDialogActions-root.MuiDialogActions-spacing.drive-dialog {
  justify-content: center;
}
.MuiDialog-paperWidthSm {
  max-width: 700px !important;
  padding-bottom: 15px;
}
button#demo-customized-button {
  bottom: 2px !important;
}

.css-orcc5d-MuiButtonBase-root-MuiButton-root {
  padding: 4px !important;
  border-radius: 0px !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  position: relative;
  float: none !important;
  left: -7px;
}
.main_board_content {
  background-color: #fff;
}
button:focus {
  outline: none !important;
}
.google_native {
  width: 40%;
  top: 25%;
  position: absolute;
  margin: 50 auto;
  left: 30%;
  right: 30%;
}
.google_native .google-btn {
  padding: 2px !important;
}
.profile-img-native {
  position: relative;
  margin: 0px 0px 30px 40%;
}
.google_native {
  background-color: #f3f1f1;
  /* padding: 6px 12px 2% 14px; */
  padding: 40px;
  border-radius: 10px;
}

.google_native button.fb-btn1 {
  border: 0;
  background-color: #00a3f1;
  color: #fff;
  font-size: 14px;

  border-radius: 5px;
  width: 100%;
  margin-bottom: 1rem;
}

button.fb-btn1.metro {
  height: 50px;
}

#subscription-modal::-webkit-scrollbar {
  width: 5px !important;
  scrollbar-width: 5px !important;
  background-color: #fff;
}

#subscription-modal::-webkit-scrollbar-thumb {
  /* border-radius: 10px; */
  background-color: #a9a9a9;
}

/* .WithStyles\(ForwardRef\(Accordion\)\)-root-2.WithStyles\(ForwardRef\(Accordion\)\)-expanded-3 {
  margin: auto;
  bottom: 20px;
} */

/* .WithStyles\(ForwardRef\(Accordion\)\)-root-2:not(:last-child) {
  border-bottom: 0;
  bottom: 20px;
} */

div#contentDiv {
  background-color: #000 !important;
  /* height: 100vh; */
}

.close-btn {
  position: absolute;
  right: 35px;
  top: 15px;
}
.logo-top {
  color: #333;
}
.check-with-text {
  color: #333;
  text-align: left;
}
span.text-point {
  padding-left: 13px;
}
.pricing {
  text-align: center;
}
/* .row.custom-row {
  width: 70%;
  margin: 1rem 0 0 12rem;
} */
.price-sec-wrap {
  width: 100%;
  float: left;
  padding: 10px 0;
  font-family: "Lato", sans-serif;
}
.main-heading {
  text-align: center;
  font-weight: 600;
  padding-bottom: 15px;
  position: relative;
  text-transform: capitalize;
  font-size: 24px;
  margin-bottom: 5px;
}
.price-box.active {
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background: linear-gradient(0.4turn, #fe2e48, #f58753);
  border-radius: 4px;
}

.profile-sidebar.d-flex.orange-line {
  bottom: 40px;
}

.inner-upgrade-content {
  margin-left: 76px;
  cursor: pointer;
}

.inner-upgrade-content .free {
  padding: 6px 0 0 5px;
  font-size: 0.7rem;
  color: #fff;
  font-weight: 400;
}
/* .MuiDialog-paperWidthSm {
  max-width: 850px !important;
} */

.price-label {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.34;
  margin-bottom: 0;
  padding: 6px 15px;
  display: inline-block;
  border-radius: 3px;
}

.price {
  font-size: 25px;
  line-height: 44px;
  margin: 0px 0 6px;
  font-weight: 900;
  color: #fff;
}
.price-info {
  font-size: 17px;
  font-weight: 400;
  line-height: 1.67;
  color: inherit;
  width: 100%;
  margin: 0;
  color: #fff;
  margin-top: 10px;
}
.plan-btn {
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  padding: 11px 30px;
  color: #000;
  margin-top: 5px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin: 0;
  border-radius: 5px;
  text-decoration: none;
  width: 100%;
  text-align: center;
  font-size: 14px;
  background-color: #fff;
  margin-top: 3rem;
  border: none;
}
.price-box-inactive {
  background-color: #ececec;
  padding: 20px;
  border-radius: 4px;
  color: #000;
  margin-bottom: 20px;
}
.price-box-inactive div {
  color: #979797;
}
span.plan-btn.subscription,
a.plan-btn.subscription {
  background: linear-gradient(0.4turn, #fe2e48, #f58753);
  width: 50%;
  margin: 25px auto;
  color: #fff;
  font-size: 16px;
}
span.plan-btn.subscribed,
a.plan-btn.subscribed {
  background: #e5e5e5;
  width: 50%;
  margin: 25px auto;
  color: rgb(0, 0, 0);
  font-size: 16px;
}
.gray-box {
  background-color: #ececec;
  padding: 2rem;
  border-radius: 5px;
  text-align: left;
}

/* new-css-13-jan-23 */

.model-header-icons {
  position: absolute;
  right: 0;
  top: 0px;
  cursor: pointer;
}
h2.head-mod {
  background-color: #333;
  color: #fff;
  padding: 7px;
}
.model-header-icons img {
  margin: 0 5px 0 5px;
}
.footer_custom {
  width: 100% !important;
  background-color: #333;
}
.footer_custom.seek-enable {
  width: 100% !important;
  background-color: rgba(51, 51, 51, 0);
}
button.play_pause svg path {
  fill: #ff5f00;
}
button.play_pause img {
  /* font-size: 40px; */
  margin: -30px 0 0 0;
  z-index: 9999;
  position: fixed;
  width: 50px;
}

.footer-timer {
  position: absolute;
  float: right;
  right: 15px;
  bottom: -6px;
  color: #ff5f00;
}
.stop-seek-icon {
  position: fixed;
  width: 30px;
  margin: -35px 0px 0px 0px;
  cursor: pointer;
}
.footer_custom.seek-enable .seek-bar {
  padding: 0 25px 20px 25px;
}
button.Mui-selected.tabs_icons {
  color: #ff5f00 !important;
}
button.tabs_icons {
  color: #fff !important;
}
.footer_custom span.MuiTabs-indicator {
  background-color: transparent;
}
.gray-bg-back-side {
  background-color: #404146;
  height: 45px;
  padding: 5px 15px 5px 5px;
}
.gray-bg-back-side p {
  color: #fff;
  font-size: 14px;
}
.Model-edit-heading-line {
  border-bottom: 1px solid #fff;
  margin-bottom: 20px;
}
.Model-edit-heading-line p {
  font-size: 14px;
  color: #fff;
}
.gray-bg-back-side.mini-gray {
  background-color: #404146;
  height: 46px;
  padding: 5px;
}
p.trans-text {
  font-size: 14px;
}
.model-center-content.MuiBox-root.css-0 {
  padding: 0 15px 15px 15px;
}

/* new child window  */
.video-player button,
.video-player svg,
.ecCRdp,
.kBCwrU,
.cWtqoj,
.cWtqoj,
.dRmQAW,
.hEjUYU {
  display: none;
}

.mini-screen .emTeya {
  min-height: 160px;
  width: 160px;
}

.video-player {
  position: fixed;
  top: 0;
  z-index: -1;
}

.video-player.mini-screen {
  z-index: 9;
}

.video-player div:first-child {
  position: fixed;
  background-color: transparent;
}
.video-player div:first-child div:last-child {
  display: none;
}

.video-player.mini-screen video {
  border-radius: 15px;
}

.video-player.mini-screen div:first-child,
.video-player.mini-screen div:first-child div:first-child {
  width: 160px;
  height: 160px;

  transition: all 500ms ease-in-out;
}

.video-player.mini-screen.topLeft div:first-child div:first-child {
  top: 50px;
  left: 20px;

  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.topRight div:first-child div:first-child {
  top: 50px;
  right: 20px;

  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.bottomLeft div:first-child div:first-child {
  bottom: 50px;
  left: 20px;

  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.bottomLeft.footerOn div:first-child div:first-child {
  bottom: 270px;
  left: 20px;
  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.bottomLeft.footerOn.footerOff
  div:first-child
  div:first-child {
  bottom: 50px;
  left: 20px;
  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.bottomRight.footerOn.footerOff
  div:first-child
  div:first-child,
.video-player.mini-screen.bottomRight.footerOn.footerOff.footerSizeChange
  div:first-child
  div:first-child {
  bottom: 50px;
  right: 20px;
  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.bottomLeft.footerOn.footerSizeChange
  div:first-child
  div:first-child {
  bottom: 230px;
  left: 20px;
  transition: all 500ms ease-in-out;
}

.video-player.mini-screen.bottomRight div:first-child div:first-child {
  bottom: 50px;
  right: 20px;
  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.bottomRight.footerOn div:first-child div:first-child {
  bottom: 270px;
  right: 20px;

  transition: all 500ms ease-in-out;
}
.video-player.mini-screen.bottomRight.footerOn.footerSizeChange
  div:first-child
  div:first-child {
  bottom: 230px;
  right: 20px;
  transition: all 500ms ease-in-out;
}

/* NEW CSS */

.sidebar_add_header {
  display: flex;
  justify-content: left;
  grid-column-gap: 10px;
  column-gap: 10px;
}

.sidebar_add_header.add_folder {
  padding: 0 0 10px 20px;
}
/* START MEDIA QUERIES */

@media (max-width: 1280px) {
  /* .row.custom-row {
    width: 100%;
    margin: 0 0 0 6rem !important;
  } */
}
@media (max-width: 991px) {
  .price-box {
    margin-bottom: 20px;
  }
}
@media (max-width: 575px) {
  .select-script {
    margin-left: 10px;
  }
  form.search-top {
    margin-bottom: 10px;
  }
  a.plan-btn.subscription {
    width: 100%;
  }
  /* .row.custom-row {
    width: 100%;
    margin: 2rem 0 0 0rem;
  } */
  .main-heading {
    font-size: 21px;
  }
  .price-box {
    margin-bottom: 20px;
  }
}
._loading_overlay_wrapper.css-79elbk {
  overflow-y: visible;
}

.checkout-page {
  padding: 2rem 8rem;
  height: 100vh;
}
.delete-account {
  text-align: center;
  padding-top: 3rem;
}
/* 
.range-margin.speed {
  width: 91vw !important;
} */

span.margin_custom {
  width: 6%;
  padding-top: 4px;
}

.running_icon {
  width: 15px;
}

.running_icon_fast {
  width: 28px;
  margin-left: 5px;
}

.toggle-footer-icon svg path {
  fill: #fff;
}
.toggle-footer-icon svg:hover > path {
  fill: #ff5f00;
}
.toggle-footer-icon {
  position: relative;
  right: 15px;
  top: 7px;
}

.cursor {
  cursor: pointer;
}

span#button-text {
  font-weight: 500;
}
.PaymentRequestButton-icon {
  display: none !important;
}

.checkout-page.new-redirect-checkout-page {
  height: 100vh;
  width: 50%;
  margin: auto;
  padding: 0px;
}

button.btn.btn-primary.btn-lg.btn-block.custom-btn.border-0.btn-login.create-account-checkout {
  /* min-width: 30%; */
  width: auto;
  margin: auto;
  height: 45px;
  font-size: 1.1rem;
}
p.text-signup.text-left.checkout-titles {
  font-weight: 600;
  font-size: 20px;
}

.toggle-text span {
  font-size: 16px;
  margin: 7px;
}

.tab-color {
  color: blue;
}
.shield_icon {
  padding-right: 2px;
}
.shield_icon path {
  fill: green;
}

span.text-shield-stripe {
  font-weight: 600;
}

button.btn.btn-primary.btn-lg.btn-block.custom-btn.border-0.btn-login.verify-account {
  background: green;
  font-size: 1.1rem;
  width: auto;
}
.checkout-page button.btn.btn-primary.btn-lg.btn-block.custom-btn {
  font-size: 1rem;
}

.default-stripe-form input {
  height: 45px;
}

.default-stripe-form .default-pay-btn {
  text-transform: uppercase;
  font-weight: 600;
  display: block;
  /* padding: 12px 15px; */
  color: #fff;
  /* margin-top: 5px; */
  overflow: hidden;
  position: relative;
  z-index: 1;
  margin: 0;
  border-radius: 5px;
  text-decoration: none;
  /* width: 50%; */
  text-align: center;
  font-size: 1.1rem;
  background-image: linear-gradient(to right, #fe2e47, #ff8c57);
  border: none;
  margin: auto;
}


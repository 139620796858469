.coupon {
  position: relative;
  width: 400px;
  height: 160px;
  margin: 50px auto;
  background-image: radial-gradient(
      circle at 1px 8px,
      transparent 6px,
      #642100 6px,
      #77503d 0px
    ),
    radial-gradient(
      circle at 199px 8px,
      transparent 6px,
      #642100 6px,
      #77503d 0px
    );
  background-size: 200px 18px;
  background-position: 0 0, 200px 0;
  /* background-repeat-x: no-repeat; */
  font-size: 60px;
  color: #fff;
  font-weight: bold;
  line-height: 160px;
  padding-left: 60px;
  box-sizing: border-box;
  cursor: pointer;
}
.coupon::before {
  position: absolute;
  content: "";
  left: 240px;
  top: 0;
  bottom: 0;
  width: 0;
  border-left: 1px dashed #fff;
}
.coupon::after {
  position: absolute;
  content: "50%";
  font-size: 26px;
  width: 70px;
  top: 50%;
  right: 2%;
  transform: translate(-50%, -50%);
  line-height: 40px;
  letter-spacing: 5px;
}

.react-contextmenu {
  background-color: #000;
  z-index: 999999;
  color: #fff;
  /* width: 100%; */
  padding: 5px 0px;

  border: 1px solid #e2e2e2;
  width: 120px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;

  background-color: #fff;
  font-size: 16px;
  border-radius: 8px;

  color: #000;
}

.deleteScript {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
}

.script__container__paragraph {
  width: auto;
  height: 42px;
  text-overflow: ellipsis;

  overflow: hidden;
}

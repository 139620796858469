.error {
  display: table;
  text-align: center;
  width: 100%;
  height: 100%;
  table-layout: fixed;
}
.cell {
  display: table-cell;
  vertical-align: middle;
}
.eror-img {
  display: inline-block;

  width: 450px;
  padding: 50px 0;
}

.error-back-home:hover {
  text-decoration: none;
}
.center{
      text-align: center;
    /* border: 3px solid green; */
    padding-top: 2rem;
    font-size: 1.3rem;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff00;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 0%);
  border: 1px solid #ced4da;
}

.gray-story-board {
  background-color: #e5e5e5;
  padding: 15px 0 5px 20px;
  border-radius: 10px 10px 0 0;
  height: 48px;
}

.optionBtn {
  float: right;
  margin: 0 16px 0 0;
}
.story-board-description {
  color: #000;
}

.editBoard {
  border: none !important;
  outline: none;
}
.textarea {
  outline: none;
}
.save {
  border: none;
  margin-left: 10px;
  font-weight: 500;
  color: #f59170;
}
[contenteditable]:focus {
  outline: 0px solid transparent;
}
h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2em;
}

.save-board-btn {
  position: absolute;
  margin: -50px 15px;
}
.story-borad p {
  font-size: 15px;
  padding: 10px 20px 35px 20px;
  overflow-y: scroll;
}
.story-borad button {
  float: right;
  display: flex;
}

.story-borad p::-webkit-scrollbar {
  width: 10px;
  scrollbar-width: 10px;
  background-color: #fff !important;
}

.story-borad textarea::-webkit-scrollbar-thumb {
  background-color: #a9a9a9 !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text {
  position: relative;
  float: left;
  left: -7px;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text:hover {
  background: none;
}

#sidebar .react-contextmenu-item:hover {
  background-color: #e7ecef;
}
textarea.story-board-description {
  font-size: 15px;
  padding: 10px 20px 35px 20px;
  overflow-y: hidden;
  border: none;
  transition: width 2s;
}

textarea.story-board-description:hover {
  overflow-y: auto;
}

/* .color_picker,
.circle-picker {
  width: 147px !important;
  padding: 5px 0px 3px 8px !important;
} */

.color_picker,
.circle-picker {
  width: 147px !important;
  padding: 5px 0px 3px 8px !important;
  position: absolute;
  z-index: 1;
}

.circle-picker {
  border: 1px solid;
  background: rgba(255, 255, 255, 0.85);
  -webkit-backdrop-filter: blur(15px);
  position: absolute;
  border-radius: 5px;
  box-shadow: 0px 3px 8px rgb(0 0 0 / 20%);
  padding: 15px;
  font-family: "Open Sans", sans-serif;
  width: 140px;
  height: 140px;
  overflow: hidden;
}
.color_picker .circle-picker span div {
  border-radius: 5px !important;
  width: 20px !important;
  height: 20px !important;
  margin-right: 7px !important;
  margin-bottom: 7px !important;
}

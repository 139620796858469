/* ul.main-folder-div {
  height: 165px;
} */
#recent_text {
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
#folder_text {
  width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.collapse.show {
  display: block;
  overflow-y: auto;
  min-height: 112px !important;
  color: inherit;
  transition: all 0.5s;
}

.main_modal {
  width: 100%;
}
/* .MuiDialog-paperWidthSm {
  max-width: 600px;
  width: 100% !important;
} */
.MuiAccordionDetails-root {
  display: grid !important;
  padding: 0px 16px 16px;
}
.recent-script-title {
  padding-left: 20px;
}
.add-new-script.mb-3 {
  padding-left: 20px;
}
ul.recent-content-body {
  padding-left: 10px;
}
.close__icon {
  color: #b6afaf;
  font-weight: 400;
  font-size: initial;
  right: 0px;
  top: -5px;
  position: relative;
  cursor: pointer;
}
/* .folder-list {
  cursor: pointer;
  word-break: break-all;
  padding: 0px 0px 0 0;
  position: relative;
  margin-left: 45px;
} */

.folder-list {
  cursor: pointer;
  word-break: break-all;
  padding: 0px 0px 0 0;
  position: relative;
  margin-left: 8px;
  background-image: url("./folder-icon.svg");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
}
.get-all-scripts {
  background-image: url("./folder-icon.svg");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-size: contain;
}
.get-all-scripts span {
  position: absolute;
  left: 17%;
}

.all-script {
  cursor: pointer;
}
.show-all-scripts:hover {
  background: none;
}
.show-all-scripts {
  font-size: 12px;
  left: 20px;
  position: relative;
  top: 0px;
  z-index: 9999;
  /* background-color: #153962; */
  width: 85%;
  /* padding: 0 0 10px 0px; */
}
.select-folders {
  height: 20px;
  width: 20px;
  margin-top: -20px;
  visibility: visible;
  left: 30px;
}
.recent__script {
  color: #fff !important;
  background: #003a66 !important;
}
.recent-script-header {
  color: #fff;
  left: 30px;
  border: none !important;
}
.recent-body ul {
  outline: none;
  list-style: none;
  font-size: 0.9em;
  /* overflow-x: hidden !important; */
}

.recent-body ul li div {
  font-size: 12px !important;
}
.MuiAccordionDetails-root {
  display: flex;
  padding: 0px 16px 16px;
}
.add-folder {
  position: relative;
  left: 25px;
}
.folder-body {
  position: relative;
  /* left: 50px; */
}

.recent-content-body li {
  cursor: pointer;

  width: 100%;
}
.recent-content-body li:hover {
  cursor: pointer;
}
.MuiDialog-paperWidthSm {
  max-width: 600px !important;
  width: 100%;
}

.MuiAccordionDetails-root {
  display: flex;
  padding: 0px 15px 0px;
}

.folder-container {
  position: relative;
}
.add-folder-icon img {
  width: 10%;
}

.folder-container .makeStyles-root-2 > * + * {
  margin-top: 0px !important;
}

.folder-container {
  flex: 1 1;
  overflow-y: auto;
  top: 0px;
  overflow-x: hidden;
}

.add-folder-icons {
  margin-left: 4px;
  position: absolute;

  z-index: 9;
  margin-top: 9px;
  left: 65px;
}
.add-folder-main {
  position: relative;
}
.add-folder-icon {
  position: absolute;
  right: 30px;
  top: 10px;
}

.add-folder-icon img {
  width: 20px;
}
.MuiAccordionDetails-root {
  display: flex;
  padding: 0px !important;
  left: 15px;
}

div#panel1d-header {
  left: 20px;
}

.folder-ul-list {
  position: relative;
  /* top: -8px; */
  /* max-height: 260px !important; */
  /* height: 40vh; */
  /* overflow-x: hidden;
  overflow-y: auto; */
}

.folder-selection {
  position: absolute;
  right: -20px;
  display: flex;
}

.folder-container::-webkit-scrollbar {
  width: 5px;
  scrollbar-width: 5px;
  background-color: #003a66 !important;
  border-radius: 5px;
}
.folder-container::-webkit-scrollbar-thumb {
  width: 8px;
  scrollbar-width: 8px;
  background-color: #a9a9a9;
  border-radius: 5px;
}
.select-all-folders {
  position: relative;
  left: 50px;
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 700;
  line-height: 1.7em;
  color: #f59170;
  cursor: pointer;
  /* margin-left: 30px; */
}
.WithStyles\(ForwardRef\(Accordion\)\)-root-2.WithStyles\(ForwardRef\(Accordion\)\)-expanded-3 {
  margin: auto;
  overflow: none;
  height: 10% !important;
}

/* .MuiCollapse-wrapperInner {
  width: 70% !important;
} */
.MuiTypography-body2 {
  font-family: "Poppins", sans-serif !important;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-text:hover {
  padding: 9px 50px 9px 50px;
  background-image: linear-gradient(to right, #fe2e47, #ff8c57);
  border: 0px;
}

.delet-alert {
  background-color: #fff;
  border: none;
  font-weight: 600;
  margin-right: 15px;
}
